import { savingCard } from "../../../../constant/constant";
import classes from "./FinCard.module.css";

const FinCard = () => {
  return (
    <>
      <div className={classes.finCard}>
        {savingCard.slice(3, 6).map((item, index) => (
          <div className={classes.main} key={index}>
            <h3>{item.heading}</h3>
            <img src={item.image} alt="" />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default FinCard;
