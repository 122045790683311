import classes from "./definition.module.css";

const Definition = () => {
  return (
    <div className={classes.main}>
      <h3>Interpretation and Definitions</h3>
      <section className={classes.intercept}>
        <h5>Interpretation</h5>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
      </section>
      <section>
        <h5>Definitions</h5>
        <p>For the purposes of these Terms and Conditions:</p>
        <div className={classes.definition}>
          <p>
            <span>Affiliate</span> means an entity that controls, is controlled
            by or is under common control with a party, where "control" means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </p>
          <p>
            <span>Country</span> refers to: Nigeria
          </p>
          <p>
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Phast Raspadori Sean
            Technology Limited, No 5, Isaac John Street, Ikeja GRA Lagos..
          </p>
          <p>
            <span>Device</span> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
          <p>
            <span>Service</span> refers to the Website.
          </p>
          <p>
            <span>Terms and Conditions</span> (also referred as "Terms") mean
            these Terms and Conditions that form the entire agreement between
            You and the Company regarding the use of the Service. This Terms and
            Conditions agreement has been created with the help of the Terms and
            Conditions Generator.
          </p>
          <p>
            <span>Third-party Social Media Service</span> means any services or
            content (including data, information, products or services) provided
            by a third-party that may be displayed, included or made available
            by the Service.
          </p>
          <p>
            <span>Website</span> refers to phastpay, accessible from
            http://www.phastpay.com
          </p>
          <p>
            <span>You </span> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable
          </p>
        </div>
      </section>
    </div>
  );
};

export default Definition;
