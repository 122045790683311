import { Security } from "../../../../constant/help";
import classes from "./card.module.css";

const Card = () => {
  return (
    <>
      <div className={classes.Card}>
        {Security.map((item, index) => (
          <div className={classes.main} key={index}>
            <div className={classes.cardSplit}>
              <div className={classes.icon}>
                <img src={item.image} alt="" />
              </div>
              <h3>{item.title}</h3>
            </div>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Card;
