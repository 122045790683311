import HeroLogo from "../../../assets/landingPage/Logo.svg";
import { NavLink } from "react-router-dom";
import classes from "./logo.module.css";

const Logo = ({ setCloseMenu }) => {
  return (
    <>
      <NavLink
        to="/"
        className={classes.main}
        onClick={() => setCloseMenu(false)}
      >
        <img src={HeroLogo} alt="" />
      </NavLink>
    </>
  );
};

export default Logo;
