import React, { useEffect } from "react";
import classes from "./policy.module.css";
import Definition from "./definition/definition";
import Personal from "./personal/personal";
import Data from "./data/data";
import Disclosure from "./disclosure/disclosure";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className={classes.main}>
        <h2>Privacy Policy</h2>
        <div className={classes.container}>
        <div className={classes.content}>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. <br />
            <br />
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Generator
          </p>
          <Definition />
          <Data />
          <Personal />
          <div className={classes.data}>
            <h5>Retention of Your Personal Data</h5>
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies. <br />
              <br />
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>

            <h5>Delete Your Personal Data</h5>
            <p>
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
              <br /> <br />
              Our Service may give You the ability to delete certain information
              about You from within the Service.
              <br /> <br />
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
              <br />
              <br /> Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
            <Disclosure />

            <h5>Security of Your Personal Data</h5>
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>

            <h5>Children's Privacy</h5>
            <p>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
              <br />
              <br />
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>

            <h5>Links to other websites</h5>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by the Company. <br />
              <br />
              The Company has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services. You further acknowledge and agree
              that the Company shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with the use of or reliance on any such
              content, goods or services available on or through any such web
              sites or services.
              <br />
              <br /> We strongly advise You to read the terms and conditions and
              privacy policies of any third-party web sites or services that You
              visit.
            </p>

            <h5>Changes to this Privacy Policy</h5>
            <p>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.{" "}
              <br />
              <br />
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
              <br /> <br />
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <h5>Contact Us</h5>
            <p>
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <div className={classes.contact}>
              <p>
                <span>By email: </span>
                <a
                  href="mailto:Info@phastpay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@phastpay.com
                </a>
              </p>
              <p>
                <span>By visiting this page on our website: </span>
                <a
                  href="http:/www.phastpay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http:/www.phastpay.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
