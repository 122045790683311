import classes from "./definition.module.css";

const Definition = () => {
  return (
    <div className={classes.main}>
      <h3>Interpretation and Definitions</h3>
      <section className={classes.intercept}>
        <h5>Interpretation</h5>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
      </section>
      <section>
        <h5>Definitions</h5>
        <p>For the purposes of these Terms and Conditions:</p>
        <div className={classes.definition}>
          <p>
            <span>Account</span> means a unique account created for You to
            access our Service or parts of our Service.
          </p>
          <p>
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Phast Raspadori Sean
            Technology Limited, No 5, Isaac John Street, Ikeja GRA Lagos.
          </p>
          <p>
            <span>Cookies</span> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </p>
          <p>
            <span>Country</span> refers to: Nigeria
          </p>
          <p>
            <span>Device</span> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
          <p>
            <span>Third-party</span> Social Media Service means any services or
            content (including data, information, products or services) provided
            by a third-party that may be displayed, included or made available
            by the Service.
          </p>
          <p>
            <span>Personal Data </span> is any information that relates to an
            identified or identifiable individual.
          </p>
          <p>
            <span>Service </span> refers to the Website.
          </p>
          <p>
            <span>Service Provider </span> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </p>
          <p>
            <span>Usage Data </span> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
          <p>
            <span>Website </span> refers to phastpay, accessible from
            http://www.phastpay.com
          </p>
          <p>
            <span>You</span> mean the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Definition;
