import classes from "./definition.module.css";

const Definition = () => {
  return (
    <div className={classes.main}>
      <h3>Interpretation and Definitions</h3>
      <section className={classes.intercept}>
        <h5>Interpretation</h5>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
      </section>
      <section>
        <h5>Definitions</h5>
        <p>For the purposes of this Cookies Policy:</p>
        <div className={classes.definition}>
          <p>
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Cookies Policy) refers to Phast Saspadori Sean
            Technology Limited, No 5, Isaac John Street, Ikeja GRA Lagos.
          </p>
          <p>
            <span>Cookies</span> means small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            details of your browsing history on that website among its many
            uses.
          </p>
          <p>
            <span>Website</span> refers to phastpay, accessible from
            <a
              href="https://www.phastpay.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.phastpay.com
            </a>
          </p>
          <p>
            <span>You</span> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable
          </p>
        </div>
      </section>
    </div>
  );
};

export default Definition;
