import classes from "./pressHero.module.css";
import Button from "../../../ui/button/button";
import { motion } from "framer-motion";

const PressHero = () => {
  return (
    <motion.div
      className={classes.main}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
    >
      <h1>Phast Press Kit </h1>
      <p>Are you writing about us ?</p>
      <Button title="Get Press kit" />
    </motion.div>
  );
};

export default PressHero;
