import { pressData } from "../../../constant/constant";
import PressCard from "./pressCard/pressCard";
import PressHero from "./pressHero/pressHero";
import classes from "./press.module.css"

const Press = () => {
  return (
    <div className={classes.main}>
      <PressHero />
      <div className={classes.gridPress}>
        {pressData.map((item, i) => (
          <PressCard {...item} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Press;
