import React, { useEffect } from "react";
import Review from "../../LandingComp/review/review";
import Waitlist from "../../ui/waitList/waitList";
import FinCard from "./finCard/finCard";
import Hero from "./heroSection/hero";
import Usage from "./usage/usage";

const CrossBorder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Hero />
      <FinCard />
      <Usage />
      <Review />
      {/* <Waitlist/> */}
    </>
  );
};

export default CrossBorder;
