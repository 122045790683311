import classes from "./hero.module.css";
import heroCard from "../../../../assets/featurePage/crossBoarder/hero.webp";
import Button from "../../../ui/button/button.js";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.heroSplit}>
          <motion.div
            className={classes.heroContent}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h1>Money Transfer</h1>
            <p>
              We provide you quick access to send and receive money from friends
              and family locally and across border at a fair exchange rate and
              fast transaction
            </p>
            <div className={classes.storeBtn}>
              <Button
                title="Tranfer Now"
                href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
              />
            </div>
          </motion.div>

          <div className={classes.heroPhone}>
            <img src={heroCard} alt="iphone" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
