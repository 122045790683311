import React, { useEffect } from "react";
import Hero from "./hero/hero";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Hero />
    </div>
  );
};

export default ContactUs;
