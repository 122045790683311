import React from "react";
import classes from "./data.module.css";

const Data = () => {
  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <h4>Collecting and Using Your Personal Data</h4>
        <h4>
          <span>Types of Data Collected</span>
        </h4>
        <h5>Personal Data</h5>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul className={classes.list}>
          <li>Email address</li>
          <li>Usage Data</li>
        </ul>

        <h5>Usage Data</h5>
        <p>
          sage Data is collected automatically when using the Service.
          <br /> Usage Data may include information such as Your Device's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that You visit, the time and date of
          Your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
          <br />
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
          <br /> We may also collect information that Your browser sends
          whenever You visit our Service or when You access the Service by or
          through a mobile device.
        </p>
        <h5>Tracking Technologies and Cookies</h5>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul className={classes.list}>
          <li>
            <span>Cookies or Browser Cookies</span> A cookie is a small file
            placed on Your Device. You can instruct Your browser to refuse all
            Cookies or to indicate when a Cookie is being sent. However, if You
            do not accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it
            will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <span> Web Beacons</span> Certain sections of our Service and our
            emails may contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel gifs) that
            permit the Company, for example, to count users who have visited
            those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>

        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. Learn more about cookies on the Privacy Policies website
          article.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>

        <div>
          <h5>Necessary / Essential Cookies</h5>
          <p>Administered by: Us</p>
          <p>Type: Session Cookies</p>
          <p>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </p>
          <h5>Functionality Cookies</h5>
          <p>Administered by: Us</p>
          <p>Type: Persistent Cookies</p>
          <p>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </p>
        </div>
        <div>
          <p>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Data;
