import classes from "./getApp.module.css";
import PlayStore from "../../assets/landingPage/playstore.svg";
import Apple from "../../assets/landingPage/Apple.svg";
import Phone from "../../assets/landingPage/footerimg.png";
import { Link } from "react-router-dom";

const GetApp = () => {
  return (
    <div className={classes.main}>
      <div className={classes.getApp}>
        <div className={classes.getAppContent}>
          <h2>Get the app now</h2>
          <p>
            Phast is now available on all devices for you. Kindly visit the
            playstore on your android device or th app store on your iOS device
            to download{" "}
          </p>
          <div className={classes.store}>
            <a
              href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
              target="_blank"
            >
              <img src={PlayStore} alt="store" />
            </a>
            <a
              href="https://apps.apple.com/app/phast-app/id6451208309"
              target="_blank"
            >
              <img src={Apple} alt="store" />
            </a>
          </div>
        </div>
        <div className={classes.phoneSplit}>
          <div className={classes.phone}>
            <img src={Phone} alt="hero-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetApp;
