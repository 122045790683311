import { useEffect } from "react";
import Review from "../../LandingComp/review/review";
import Waitlist from "../../ui/waitList/waitList";
import FinCard from "../bill/finCard/finCard";
import Hero from "../bill/heroSection/hero";
import Usage from "../bill/usage/usage";

const Bill = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Hero />
      <FinCard />
      <Usage />
      <Review />
      {/* <Waitlist/> */}
    </div>
  );
};

export default Bill;

