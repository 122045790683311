import { useEffect } from "react";
import Hero from "../../components/LandingComp/heroSection/hero";
import Partner from "../../components/LandingComp/partners/partner";
import Review from "../../components/LandingComp/review/review";
import Solution from "../../components/LandingComp/solution/solution";
import Usage from "../../components/LandingComp/usage/usage";
import Waitlist from "../../components/ui/waitList/waitList";


const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Hero />
      <Solution />
      <Usage />
      <Review />
      <Partner />
      {/* <Waitlist/> */}
    </>
  );
};

export default Landing;
