import FinCard from "./finCard/finCard";
import classes from "./solution.module.css";

const Solution = () => {
  return (
    <div className={classes.main}>
      <div>
        <div className={classes.solutionContent}>
          <h2>Financial Solution for Everyone</h2>
          <p>
            We have provided flexible and easy to use features to help you
            optimize your financial lifestyle
          </p>
        </div>
          <FinCard />
      </div>
    </div>
  );
};

export default Solution;
