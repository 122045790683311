import { useEffect } from "react";
import Question from "./question/question";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Question />
    </div>
  );
};

export default Faq;
