import React from "react";
import { Link } from "react-router-dom";
import classes from "./usage.module.css";
import { feature } from "../../../../constant/constant";
import { motion } from "framer-motion";

const Usage = () => {
  return (
    <div className={classes.main}>
      {feature.slice(16, 19).map((item, index) => (
        <div
          className={`${classes.usageSplit} ${
            item.reverse ? classes.usageReverse : ""
          }`}
          key={index}
        >
          <motion.div
            className={classes.usageContent}
            initial={{ opacity: 0, x: `${item.reverse ? "100px" : "-100px"}` }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h4>{item.label}</h4>
            <h1>{item.heading}</h1>
            <p>{item.text}</p>
          </motion.div>

          <div className={classes.usageImage}>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              src={item.Image}
              alt="iphone"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Usage;
