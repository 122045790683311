import classes from "./button.module.css";
import { NavLink } from "react-router-dom";

const Button = ({ href, title }) => {
  return (
    <>
      <a href={href} target="_blank" className={classes.main}>
        {title}
      </a>
    </>
  );
};

export default Button;
