import card from "../assets/landingPage/card-icon.svg";
import utility from "../assets/landingPage/utility-icon.svg";
import savings from "../assets/landingPage/savings-icon.svg";
import cashback from "../assets/landingPage/cashback-icon.svg";
import transfer from "../assets/landingPage/transfer-icon.svg";

export const featureData = [
  {
    icon: card,
    title: "Cards",
    link: "/features/cards",
  },
  {
    icon: transfer,
    title: "Money Transfer",
    link: "/features/transfer",
  },
  {
    icon: savings,
    title: "Savings",
    link: "/features/savings",
  },
  {
    icon: cashback,
    title: "Cashback",
    link: "/features/cashback",
  },
  {
    icon: utility,
    title: "Utility and Bills",
    link: "/features/bills",
  },
];
export const companyData = [
  {
    title: "Join our team",
    link: "/company/join-us",
  },
  {
    title: "About Us",
    link: "/company/about-us",
  },
  {
    title: "Press",
    link: "/company/press/",
  },
  // {
  //   title: "Blog",
  //   href: 'https://medium.com/@info_74612',
  // },
];
export const helpData = [
  {
    title: "FAQs",
    link: "/help/faqs",
  },
  {
    title: "Security",
    link: "/help/security",
  },
  {
    title: "Contact Us",
    link: "/help/contact-us",
  },
];
