import React from "react";
import classes from "./hero.module.css";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className={classes.main}>
      <motion.h2
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        Phast Security
      </motion.h2>
    </div>
  );
};

export default Hero;
