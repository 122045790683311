import React, { useEffect } from 'react'
import JoinHero from './joinHero/joinHero'
import JoinPage from './joinPage/joinPage'

const JoinUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <JoinHero />  
      <JoinPage />  
    </>
  )
}

export default JoinUs