import React, { useEffect } from "react";
import classes from "./terms.module.css"
import Definition from "./definition/definition";
import Data from "./data/data";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className={classes.main}>
      <h2>Terms & Conditions </h2>
      <div className ={classes.container}>
      <div className={classes.content}>
        <Definition/>
        <Data/>
      </div>
      </div>
    </div>
  );
};

export default Terms;
