import classes from "./pressCard.module.css";

const PressCard = ({img, category, news, date, color, backgroundColor}) => {
  return (
    <div className={classes.main}>
      <img src={img} alt="pictures" />
      <div className={classes.pressContent}>
        <div className={classes.cat}>
          <p className={classes.category} style={{color: color, backgroundColor: backgroundColor}}>{category}</p>
          <p className={classes.date}>{date}</p>
        </div>
        <p className={classes.news}>
          {news}
        </p>
      </div>
    </div>
  );
};

export default PressCard;
