import { useRoutes } from "react-router";
import AboutUs from "./components/company/aboutUs/aboutUs";
import JoinUs from "./components/company/JoinUs/joinUs";
import Press from "./components/company/press/press";
import Cookies from "./components/cookies/cookies";
import Bill from "./components/features/bill/bill";
import CardFeatures from "./components/features/card/card";
import Cashback from "./components/features/cashBack/cashBack";
import CrossBorder from "./components/features/crossBorder/crossBorder";
import SavingFeatures from "./components/features/saving/saving";
import ContactUs from "./components/help/contactUs/contactUs";
import Faq from "./components/help/faqs/faq";
import Security from "./components/help/security/security";
import Policy from "./components/policy/policy";
import MainLayout from "./layout/mainLayout/mainLayout";
import Landing from "./pages/landing/landing";
import JoinUsLayout from "./layout/joinUsLayout/joinUsLayout";
import Terms from "./components/terms/terms";


const Routes = () => {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <Landing /> },
        {
          path: "/cookies",
          element: <Cookies />,
        },{
          path: "/Policy",
          element: <Policy/>,
        },
        {
          path: "/Terms&Condition",
          element: <Terms/>,
        },
      ],
    },
    {
      path: "/features",
      element: <MainLayout />,
      children: [
        { path: "/features/cards", element: <CardFeatures /> },
        { path: "/features/transfer", element: <CrossBorder /> },
        { path: "/features/savings", element: <SavingFeatures /> },
        { path: "/features/cashback", element: <Cashback /> },
        { path: "/features/bills", element: <Bill /> },
      ],
    },
    {
      path: "/company",
      element: <MainLayout />,
      children: [
        { path: "/company/join-us", element: <JoinUs /> },
        { path: "/company/about-us", element: <AboutUs /> },
        { path: "/company/press", element: <Press /> },
      ],
    },
    {
      path: "/company/join-us/:id",
      element: <JoinUsLayout />,
    },
    {
      path: "/help",
      element: <MainLayout />,
      children: [
        { path: "/help/faqs", element: <Faq /> },
        { path: "/help/security", element: <Security /> },
        { path: "/help/contact-us", element: <ContactUs /> },
      ],
    },
  ]);
};

export default Routes;
