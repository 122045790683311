import React from "react";
import classes from "./usage.module.css";
import { feature } from "../../../constant/constant";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

const Usage = () => {
  return (
    <div className={classes.main}>
      {feature.slice(0, 5).map((item, index) => (
        <div
          className={`${classes.usageSplit} ${
            item.reverse ? classes.usageReverse : ""
          }`}
          key={index}
        >
          <motion.div
            className={classes.usageContent}
            initial={{
              opacity: 0.5,
              x: `${item.reverse ? "100px" : "-100px"}`,
            }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1>{item.heading}</h1>
            <p>{item.text}</p>
            <Link to={item.navigate}>
              {item.link} <BiChevronRight size={20} />
            </Link>
          </motion.div>

          <div className={classes.usageImage}>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              src={item.Image}
              alt="iphone"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Usage;
