import classes from "./cookies.module.css";
import Definition from "../definition/definition";
import { useEffect } from "react";

const Cookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={classes.main}>
      <div className={classes.cookiesInfo}>
        <div>
          <h1>Cookies Policy</h1>
          <p>
            This Cookies Policy explains what Cookies are and how We use them.
            You should read this policy so You can understand what type of
            cookies We use, or the information We collect using Cookies and how
            that information is used. This Cookies Policy has been created with
            the help of the Cookies Policy Generator. <br />
            <br /> Cookies do not typically contain any information that
            personally identifies a user, but personal information that we store
            about You may be linked to the information stored in and obtained
            from Cookies. For further information on how We use, store and keep
            your personal data secure, see our Privacy Policy. <br />
            <br /> We do not store sensitive personal information, such as
            mailing addresses, account passwords, etc. in the Cookies We use.
          </p>
        </div>
        <Definition />
        <section>
          <h3>The use of the cookies</h3>
          <div>
            <h4>Type of cookies we use </h4>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              your web browser.
              <br />
              <br /> We use both session and persistent Cookies for the purposes
              set out below:
            </p>
          </div>
          <div className={classes.essential}>
            <p>
              <span>Necessary / Essential Cookies</span> <br /> Type: Session
              Cookies <br /> Administered by: Us <br /> Purpose: These Cookies
              are essential to provide You with services available through the
              Website and to enable You to use some of its features. They help
              to authenticate users and prevent fraudulent use of user accounts.
              Without these Cookies, the services that You have asked for cannot
              be provided, and We only use these Cookies to provide You with
              those services.
            </p>
            <p>
              <span>Functionality Cookies</span> <br /> Type: Persistent Cookies{" "}
              <br /> Administered by: Us <br />
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </div>
        </section>
        <section className={classes.choice}>
          <h3>Your Choices Regarding Cookies</h3>
          <p>
            If You prefer to avoid the use of Cookies on the Website, first You
            must disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may
            use this option for preventing the use of Cookies at any time.{" "}
            <br />
            <br /> If You do not accept Our Cookies, You may experience some
            inconvenience in your use of the Website and some features may not
            function properly. <br />
            <br /> If You'd like to delete Cookies or instruct your web browser
            to delete or refuse Cookies, please visit the help pages of your web
            browser.
          </p>
          <p className={classes.browser}>
            For the Chrome web browser, please visit this page from Google:
            <a
              href="https://support.google.com/accounts/answer/32050"
              target="_blank"
              rel="noopener noreferrer"
            >
              support.google.com/accounts/answer/32050
            </a>{" "}
            <br />
            For the Internet Explorer web browser, please visit this page from
            Microsoft:{" "}
            <a
              href="http://support.microsoft.com/kb/278835"
              target="_blank"
              rel="noopener noreferrer"
            >
              support.microsoft.com/kb/278835
            </a>{" "}
            <br />
            For the Firefox web browser, please visit this page from Mozilla:
            <a
              href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
              target="_blank"
              rel="noopener noreferrer"
            >
              support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </a>
            <br />
            For the Safari web browser, please visit this page from Apple:
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer"
            >
              support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>
            <br />
          </p>
          <p>
            For any other web browser, please visit your web browser's official
            web pages.
          </p>
        </section>
        <section className={classes.moreInfo}>
          <h3>More information about cookies </h3>
          <p>You can learn more about cookies: What Are Cookies?.</p>
        </section>
        <section>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <p className={classes.contact}>
            By email: <a href="mailto: info@phastpay.com">info@phastpay</a>{" "}
            <br /> By visiting this page on our website:
            <a
              href="
            https://www.phastpay.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.phastpay.com
            </a>{" "}
          </p>
        </section>
      </div>
    </div>
  );
};

export default Cookies;
