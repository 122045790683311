import classes from "./partner.module.css"
import Partner1 from "../../../assets/landingPage/partner-1.svg"
import Partner2 from "../../../assets/landingPage/partner-2.svg"
import Partner3 from "../../../assets/landingPage/partner-3.svg"

const Partner = () => {
  return (
    <div className={classes.main}> 
        <h2>Our Partners</h2>
        <div className={classes.partner}>
            <img src={Partner1} alt="logo" />
            <img src={Partner2} alt="logo" />
            <img src={Partner3} alt="logo" />
        </div>
    </div>
  )
}

export default Partner