import React from "react";
import classes from "./disclosure.module.css"

const Disclosure = () => {
  return (
    <div className={classes.main}>
      <div className={classes.main}>
        <h5>Disclosure of Your Personal Data</h5>

        <h6>Business Transactions</h6>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <h6>Law enforcement</h6>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h6>Other legal requirements</h6>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
      </div>
      <ul className={classes.list}>
        <li >Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrong doing in connection with the
          Service
        </li>
        <li>Protect against legal liability</li>
      </ul>
    </div>
  );
};

export default Disclosure;
