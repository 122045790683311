import classes from "./footer.module.css";
import GetApp from "../../getApp/getApp";
import Logo from "../../../assets/landingPage/Logo.svg";
import Apple from "../../../assets/landingPage/apple-sm.svg";
import PlayStore from "../../../assets/landingPage/playstore-sm.svg";
import Whatsapp from "../../../assets/landingPage/whatsapp.svg";
import twitter from "../../../assets/landingPage/twitter.svg";
import facebook from "../../../assets/landingPage/facebook.svg";
import instagram from "../../../assets/landingPage/instagram.svg";
import linkedIn from "../../../assets/landingPage/linkedln.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.main}>
      <GetApp />
      <footer>
        <div className={classes.footerContent}>
          <div className={classes.logo}>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className={classes.footerInner}>
            <div className={classes.contents}>
              <h4>Features</h4>
              <p>
                <Link to="/features/cards">Cards</Link>
              </p>
              <p>
                <Link to="/features/transfer">Money Transfer</Link>
              </p>
              <p>
                <Link to="/features/savings">Savings</Link>
              </p>
              <p>
                <Link to="/features/cashback">Cashback</Link>
              </p>
              <p>
                <Link to="/features/bills">Bill</Link>
              </p>
            </div>
            <div className={classes.contents}>
              <h4>Company</h4>
              <p>
                <Link to="/company/join-us">Join Us</Link>
              </p>
              <p>
                <Link to="/company/about-us">About Us</Link>
              </p>
              <p>
                <a
                  href="https://medium.com/@info_74612"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </p>
            </div>
            <div className={classes.contents}>
              <h4>Help</h4>
              <p>
                <Link to="/help/faqs">FaQs</Link>
              </p>
              <p>
                <Link to="/help/security">Security</Link>
              </p>
              <p>
                <Link to="/help/contact-us">Contact Us</Link>
              </p>
            </div>
            <div className={classes.contents}>
              <h4>Features</h4>
              <p>
                <Link to="/Terms&Condition">Terms and Service</Link>
              </p>
              <p>
                <Link to="/Policy">Privacy and Policy</Link>
              </p>
              <p>
                <Link to="/cookies">Cookies</Link>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.innerFooter}>
          <div className={classes.store}>
            <a
              href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
              target="_blank"
            >
              <img src={PlayStore} alt="store-icon" />
            </a>
            <a
              href="https://apps.apple.com/app/phast-app/id6451208309"
              target="_blank"
            >
              <img src={Apple} alt="store-icon" />
            </a>
          </div>
          <div className={classes.socials}>
            <a
              href="https://chat.whatsapp.com/LyO0IQksLYXHA1SWmaSFoQ"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Whatsapp} alt="whatsapp" />
            </a>
            <a href={null}>
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://twitter.com/phast_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="twitter" />
            </a>
            <a
              href="https://instagram.com/phast.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/phasttech/"
              target="_blank"
            >
              <img src={linkedIn} alt="aedin" />
            </a>
          </div>
        </div>
        <p className={classes.address}>
          ©2022 Phast, a financial service provided by Phast Raspadori Sean
          Technology Limited (RC 1982206), is a digital payment solution that
          empowers individuals with seamless payment methods both locally and
          across borders.
          <br /> We are in full compliance with the National Data Protection
          Adequacy Programme (“NaDPAP”) white list criteria, and we are included
          on the NaDPAP whitelist.
          <br /> <br />
        </p>
        <p className={classes.street}>
          <span>Registered Address:</span> No 5, Issac John Street GRA Ikeja
          Lagos
        </p>
      </footer>
    </div>
  );
};

export default Footer;
