import Fin from "../assets/landingPage/fin1.svg";
import Fin1 from "../assets/landingPage/virtual-card.svg";
import Fin2 from "../assets/landingPage/saving-card.svg";
import phast1 from "../assets/converted/image 40.png";
import phast2 from "../assets/converted/image 41.webp";
import phast3 from "../assets/converted/image 42.webp";
import phast4 from "../assets/converted/image 43.webp";
import phast5 from "../assets/converted/image 44.webp";
import phast6 from "../assets/converted/image 45.webp";
import phast7 from "../assets/converted/image 46.webp";
import phast8 from "../assets/converted/image 47.webp";
import phast9 from "../assets/converted/image 48.webp";
import phast10 from "../assets/converted/image 49.png";
import phast11 from "../assets/converted/image 50.png";
import phast12 from "../assets/converted/image 51.webp";
import phast13 from "../assets/converted/image 52.webp";
import phast14 from "../assets/converted/image 53.webp";
import phast15 from "../assets/converted/image 54.webp";
import phast16 from "../assets/converted/image 55.webp";
import phast17 from "../assets/converted/image 56.webp";
import phast18 from "../assets/converted/image 57.png";
import phast19 from "../assets/converted/image 58.png";
import Jar from "../assets/featurePage/Saving/Jar.svg";
import Dollar from "../assets/featurePage/Saving/dollar.svg";
import Piggy from "../assets/featurePage/Saving/piggy-bank.svg";
import Refferal from "../assets/featurePage/cashback/referral.svg";
import People from "../assets/featurePage/cashback/people.svg";
import Electricity from "../assets/featurePage/bill/electricity.svg";
import Cable from "../assets/featurePage/bill/cable.svg";
import Airtime from "../assets/featurePage/bill/airtime.svg";
import Tranfer from "../assets/featurePage/crossBoarder/transfer.svg";
import Eye from "../assets/featurePage/crossBoarder/eye.svg";
import Safe from "../assets/featurePage/crossBoarder/safe.svg";
import Card from "../assets/featurePage/card/card.svg";
import Customer1 from "../assets/landingPage/customer-1.png";
import Customer2 from "../assets/landingPage/customer-2.png";
import Customer3 from "../assets/landingPage/customer-3.png";
import Customer4 from "../assets/landingPage/customer-4.png";
import Press1 from "../assets/company/press-1.webp";
import Press2 from "../assets/company/press-2.webp";
import Press3 from "../assets/company/press-3.webp";
import Press4 from "../assets/company/press-4.webp";

export const feature = [
  {
    heading: "Get Phast card ",
    text: "We provide you the access to a virtual dollar credit that helps you pay for your online subscriptions and purchases",
    link: "Learn more ",
    navigate: "/features/cards",
    Image: phast1,
  },
  {
    heading: "Money Transfer",
    text: "We provide you quick access to send and receive money from friends and family locally and across border at a fair exchange rate and fast transaction",
    link: "Learn more ",
    navigate: "/features/transfer",
    Image: phast2,
    reverse: true,
  },
  {
    heading: "Save for rainy days",
    text: "We provide you the option to save your money, you can be flexible with your plan and also set saving goals",
    link: "Learn more ",
    navigate: "/features/savings",
    Image: phast3,
  },
  {
    heading: "Cashback & Referal Bonus",
    text: "Earn a cashback when you use our card for your online transactions and also get to earn when you refer friends and family to the phast family.",
    link: "Learn more ",
    navigate: "/features/cashback",
    Image: phast4,
    reverse: true,
  },
  {
    heading: "Utility & Bill Payments",
    text: "Pay for anything, we enable you to subscrible to your cable TV, Electricity and also purchase Airtime and Internet Data right with just few clicks",
    link: "Learn more ",
    navigate: "/features/bills",
    Image: phast5,
  },
  {
    label: "Open Deposit ",
    heading: "Save with us and watch your money grow ",
    text: "Open a deposit with a certain amount for a period of time and get up to 15%, You also have the option to keep rolling over if you wish to save for a longer period",
    Image: phast6,
    reverse: true,
  },
  {
    label: "Create Jar ",
    heading: "Learn financial Displine  ",
    text: "Set a certain amount as a target and task us to help you save to meet this target, Amount to be saved from your incomes could be daily, weekly, monthly or yearly depending on your convenience and you can earn up to 10% on your jars.",
    Image: phast7,
  },
  {
    label: "Safe and Secured ",
    heading: "You are in safe hands",
    text: "We are insured by NDIC and certified by CBN.You do not need to worry, You are saving with the right hands and in safe hands",
    Image: phast8,
    reverse: true,
  },
  {
    label: "Physical Card  ",
    heading: "Card delivery to your doorstep",
    text: "No queue involved.You can order  our card and have it delivered to you easily.",
    Image: phast9,
    reverse: true,
  },
  {
    label: "Virtual Card ",
    heading: "Shop from your favourite online stores ",
    text: "We offer a limit of $1000 on our virtual card every month. It makes it easy for you to shop and make payment  from your favourite online store abroad and at a transparent exchange rate.",
    Image: phast10,
  },
  {
    label: "Safe and Secured ",
    heading: "Your cards are safe with us ",
    text: "Both Physical cards and Virtual cards are 100% secured, With the security put in place your card is safe. From block card  to set spending limits monthly.",
    Image: phast11,
    reverse: true,
  },
  {
    label: "Cashback",
    heading: "You are not just swiping your card, you are earning",
    text: "Get up to 0.5% cashback when you use your virtual card to pay for certain types of services maybe online or offline, You equivalently earn cashback according to the category of your spending",
    Image: phast12,
    reverse: true,
  },
  {
    label: "Refferal Bonus",
    heading: "Earn when you invite friends and family",
    text: "You have a friend or a family you want to convince to join Phast family, You will get #1000 anytime you invite a friend or family to use Phast. The more people you bring in the more you get",
    Image: phast13,
  },
  {
    label: "Free For All",
    heading: "You are not left out",
    text: "Cashbacks and referral bonus is for all Phast family, No hidden tasks, No buts and ifs, Just enjoy the features.",
    Image: phast14,
    reverse: true,
  },

  {
    label: "Airtime",
    heading: "Recharge your line easily with phast",
    text: "Recharge your line easily with phast",
    Image: phast15,
    reverse: true,
  },
  {
    label: "Bill payment",
    heading: "Don’t experience any disconnect",
    text: "With few clicks enjoy your cable tv and electricty without any interruption.",
    Image: phast16,
  },
  {
    label: "Money Transfer",
    heading: "Send and recieve money both locally and internationally ",
    text: "Do you need to send money or recieve money  quickly? With phast the money arrives almost instantly.",
    Image: phast17,
    reverse: true,
  },

  {
    label: "Cross Border ",
    heading: "Take advantage of our favorable exchange rate.",
    text: "When sending money abroad, you can benefit from our favorable exchange rate.",
    Image: phast18,
  },
  {
    label: "Money Transfer",
    heading: "No hidden charges ",
    text: "When you use phast, you won't have to worry about hidden fees because we are as transparent as it gets.",
    Image: phast19,
    reverse: true,
  },
];

export const savingCard = [
  {
    heading: "Send and Receive money ",
    image: Fin,
    text: "Do not be limited by location. Send and receive money swiftly across 120+ countries and make bill payments too.",
  },
  {
    heading: "Virtual & physical card",
    image: Fin1,
    text: "We provide you with physical debit card and a virtual dollar debit card for online transactions ",
  },
  {
    heading: "Savings",
    image: Fin2,
    text: "We encourage financial discipline so that as you spend your also save to meet predetermined goal",
  },
  {
    heading: "PHYSICAL CARD",
    image: Card,
    text: "With our physical card payment can be made smoothly and is widly accepted at almost every payment point",
  },
  {
    heading: "Virtual card",
    image: Card,
    text: "With our physical card payment can be made smoothly and is widly accepted at almost every payment point.  ",
  },
  {
    heading: "SECURE",
    image: Safe,
    text: "With several security put in place your card are very safe with us .",
  },
  {
    heading: "CASHBACK",
    image: Refferal,
    text: "Get 0.5% returns everytime you use your virtual card for any online payments and services",
  },
  {
    heading: "referral ",
    image: Refferal,
    text: "Get #1000 bonus everytime you invite your friends and family to join the  Phast family",
  },
  {
    heading: "Free For All",
    image: People,
    text: "There is no criteria for any Phast family to enjoy this unending benfits and bonuses",
  },
  {
    heading: "Airtime ",
    image: Airtime,
    text: "Purchase airtime quickly and conveniently with a few taps and no additional fees.",
  },
  {
    heading: "Electricity",
    image: Electricity,
    text: "You can quickly and simply pay your utility bills.",
  },

  {
    heading: "Cable Bill",
    image: Cable,
    text: "Make your payment conviniently and continue to enjoy your favourite channels.",
  },
  {
    heading: "INSTANT TRANSFER",
    image: Tranfer,
    text: "Make cross border transfer and local transfer they recieve it almost immediately.",
  },
  {
    heading: "NO HIDDEN CHARGES",
    image: Eye,
    text: "When it comes to our charges,we are as transparent as it gets.",
  },

  {
    heading: "SAFE",
    image: Safe,
    text: "You are safe with us as a result of the security measures we have put in place.",
  },
  {
    heading: "OPEN DEPOSIT",
    image: Piggy,
    text: "Get more interest the more and longer you save your money with us. Simply put yor money to work for you.",
  },
  {
    heading: "CREATE JAR",
    image: Jar,
    text: "Set a target amount you will like to achieve regardless of time, You can then break your jar and spend",
  },

  {
    heading: "ROLL OVER",
    image: Dollar,
    text: "You can roll over your deposited savings should you wish to continue after the stipulated time is complete.",
  },
];

export const reviewData = [
  {
    icon: Customer1,
    name: "Olusegun Comfort",
    review:
      "  With Phast, I am now able to pay for my PHCN bills from the comfort of my home. So convenient",
  },
  {
    icon: Customer2,
    name: "Adeniyi Rufai",
    review:
      " Thanks to phast’s jar, I have saved enough money to buy my new iphone.",
  },
  {
    icon: Customer3,
    name: "Jokotoye David",
    review:
      "I just joined the best digital bank in Nigeria.I have no complaints whatsoever since I started using Phast.",
  },
  {
    icon: Customer4,
    name: "Chioma",
    review:
      "This is the best banking app! I’m enjoying seamles and fast banking services with Phast . (No pun intended).",
  },
];

export const pressData = [
  {
    img: Press1,
    date: "31/Aug/2022",
    news: "Gbas Gbos in the 9ja fintech ecosystem.",
    category: "Techcabal",
    backgroundColor: "#FFF4E7",
    color: "#FF9314",
  },
  {
    img: Press2,
    date: "31/Aug/2022",
    news: "Phast raised 4.3 million in seed round from Liquid2 Ventures.",
    category: "PhastOfficial",
    backgroundColor: "#C1DCF5",
    color: "#00529C",
  },
  {
    img: Press3,
    date: "31/Aug/2022",
    news: "Flutterwave has secured a switching and processing license",
    category: "Hacksultan",
    backgroundColor: "#F1E8FA",
    color: "#741CCD",
  },
  {
    img: Press4,
    date: "31/Aug/2022",
    news: "Forbes under 30 CEOs , Ademola Akinyemi ,Founder Phast",
    category: "Forbes",
    backgroundColor: "#FEE5EE",
    color: "#F40058",
  },
];

export const positionData = [
  {
    id: "engineering-details",
    category: "Engineering",
    position: "Senior React Native Engineer, Design Systems",
    location: "Lagos",
    department: "engineering",
    type: "Remote",
    description: [
      {
        content:
          "Responsible for designing Pecuniapay front-end products, including formulating product strategies, designing product functions, and conducting competitive products analysis according to user characteristics and our business development plans; ",
      },
      {
        content:
          "Coordinate resources to conduct requirements analysis, PRD implementation, project advancement, and user feeedback follow-up to continuously improve product experience :",
      },
      {
        content:
          "Carry out product optimization and iteration based on data analysis or market demand ; and keep enhancing product experience to reach our goals",
      },
    ],
    requirement: [
      {
        content: "Bachelor’s degree or above;",
      },
      {
        content:
          "Familar with mobile Internet products; have a good sense of user experience and fast learning ability;        ",
      },
      {
        content:
          "Strong  logical and analytical abilities to generalize the essence from appearance; enthusiastic about learning, candidates with articles published in various product manager communities are preferred;",
      },
      {
        content:
          "Proficiency  in using AXURE, VISIO, PPT, mind map and other tools, excellent communication skills, writing skills, and aesthetic judgment;",
      },
      {
        content:
          "Have an open mind for feedback, a teamwork spirit, and the ability to coordinate and promote projects.",
      },
    ],
  },
  {
    id: "engineering-details-2",
    category: "Engineering",
    position: "Mid-Level React Native Engineer, Design Systems",
    location: "Abuja",
    department: "engineering",
    type: "Remote",
    content: "i am engineering twoooo",
  },
  {
    id: "legal-details",
    category: "Legal",
    position: "Senior Legal Practional",
    location: "Lagos",
    department: "legal",
    type: "Remote",
    content: "this is a legal",
  },
  {
    id: "product-details",
    category: "Product",
    position: "Product Manager",
    location: "Abuja",
    department: "product",
    type: "Remote",
  },
  {
    id: "resource-details",
    category: "Human Resource",
    position: "HR Manager",
    location: "Lagos",
    department: "human resource",
    type: "Remote",
  },
  {
    id: "resource-detaials-2",
    category: "Human Resources",
    position: "HR Manager",
    location: "Abuja",
    department: "human resource",
    type: "Remote",
  },
];
