import classes from "./joinHero.module.css";
import Rocket from "../../../../assets/company/rocket.svg";
import { motion } from "framer-motion";

const JoinHero = () => {
  return (
    <div className={classes.main}>
      <motion.div
        className={classes.hero}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        <h2>
          Join Our Incredible team <img src={Rocket} alt="" />
        </h2>
        <p>
          We promote a well-defined company culture that provides the right
          environment for employees to meet their own needs while working
          towards the company’s goals.
        </p>
      </motion.div>
    </div>
  );
};

export default JoinHero;
