import Biometric from "../assets/help/biometric.svg";
import Verification from "../assets/help/verification.svg";
import Pin from "../assets/help/pin.svg";
import Protection from "../assets/help/protection.svg";
import Complaint from "../assets/help/complaint.svg";
import Password from "../assets/help/password.svg";    

export const Faq = [
  {
    question: "What is Phast ?",
    answer:
      "Phast is a Neo bank. Our mobile application is centered around financial management with a critical focus on creating seamless payment modes and transactions both locally and across borders, at a subsidized rate.We came from frustration with a flawed and inconsistent payment system in Nigeria, now we are here. And we are here because of you."
  },
  {
    question: "Can I transfer money to other countries?",
    answer:
      "Yes you can. With our cross border transfer feature, you can send money to your friends, family or acquaintances in other countries."
  },
  {
    question: "Does Phast have a dollar card ?",
    answer:
      "Yes. Go on the phast app to create and activate your virtual dollar card today!"
  },
  {
    question: "Is my money safe with phast?",
    answer:
    "Your funds are 100% safe with us.Phast is insured by the NDIC and certified by the CBN.",
  },
  {
    question: "Can I save on Phast ?",
    answer:
      "Yes you can.You can save towards your goal via our deposit feature or via our jar feature.With the deposit feature, you’re able to save your money at your discretion. With the jar feature, you are able to save towards your goal, by committing to saving a target amount every week, every month, three monthly, six monthly, nine monthly, or yearly.Go on the phast app to start saving now!",
  },
  { 
    question: "How much do I get for referring a friend ?",
    answer:
      "You get N500",
  },
  {
    question: "When am I eligible for cashback ?",
    answer:
      "You become eligible for cashback once you start using your virtual dollar card for online purchases.",
  },
  {
    question: "Can I make online payments with Phast ?",
    answer:
      "Absolutely! With our virtual dollar card, get ready to make online purchases from anywhere in the world. Phast got you!",
  },
  {
    question: "Is there provision for plastic card ?",
    answer:
      "Yes there is. Go on the phast app now to request for your physical card.Input your delivery details and your card will be delivered to you in no time and at zero cost. Awesome, right?",
  },
  {
    question: "Is Phast available in all countries ?",
    answer:
      "Phast is operating in Nigeria only for now. But make no mistakes, we are coming to other parts of the world, real soon!",
  }, 
];

export const Security = [
  {
    image: Biometric,
    title: "Biometrics",
    text: "Phast  app allows the use of biometrics (Face ID and fingerprint). If you own a device with Face ID and fingerprint scanners, you can use it to unlock your app and carry out transactions.",
  },
  {
    image: Verification,
    title: "Verification Document",
    text: "During the onboarding process, we request your BVN (Bank verification number), which allows us to validate the information you provided and confirm your identity.",
  },
  {
    image: Pin,
    title: "Pin",
    text: "You must create a four-digit PIN that is strictly numbers and can be used to authorize transactions. You also must create a PIN for your ATM card in order to carry out transactions.",
  },
  {
    image: Password,
    title: "Password",
    text: "During onboarding, you must create a 6-digit password that will be used to unlock your account and will be required when making changes to your account.",
  },
  {
    image: Protection,
    title: "Advance Data Protection",
    text: "During onboarding, you must create a 6-digit password that will be used to unlock your account and will be required when making changes to your account.",
  },
  {
    image: Complaint,
    title: "PCI DSS Complaint",
    text: "Your money is in safe hands , we are PCI DSS complaint.Our systems are scanned twice daily to check against malicious activities",
  },
];
