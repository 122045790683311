import React from "react";
import Shield from "../../../../assets/help/shield.svg";
import classes from "./title.module.css";

const Title = () => {
  return (
    <div className={classes.main}>
      {" "}
      <h5>How we keep you safe</h5>{" "}
      <i>
        <img src={Shield} alt="" />
      </i>
    </div>
  );
};

export default Title;
