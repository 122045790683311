import { useState } from "react";
import classes from "./question.module.css";
import { BsChevronDown } from "react-icons/bs";
import { Faq } from "../../../../constant/help";

const Question = () => {
  return (
    <div className={classes.main}>
      <div className={classes.title}>
        <h1>Frequently Asked Questions. </h1>
      </div>
      {Faq.map((item, i) => (
        <Dropdown key={`question-${i}`} {...item} />
      ))}
    </div>
  );
};

const Dropdown = ({ question, answer }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={classes.questionSplit} onClick={() => setShow(!show)}>
      <div className={classes.questionContent}>
        <div className={classes.arrowSplit}>
          <h4>{question}</h4>
          <i>
            <BsChevronDown className={show ? classes.rotateUp : ""} />
          </i>
        </div>
        <div
          className={show ? `${classes.text} ${classes.show}` : classes.text}
        >
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default Question;
