import classes from "./hero.module.css";
import heroCard from "../../../../assets/featurePage/bill/hero.webp";
import Button from "../../../ui/button/button.js";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.heroSplit}>
          <motion.div
            className={classes.heroContent}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h1>Utility & Bill Payments</h1>
            <p>
              Pay for anything, we enable you to subscrible to your cable TV,
              Electricity and also purchase Airtime and Internet Data right with
              just few clicks
            </p>
            <div className={classes.storeBtn}>
              <Button
                title="Get started"
                href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
              />
            </div>
          </motion.div>

          <div className={classes.heroPhone}>
            <img src={heroCard} alt="iphone" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
