import classes from "./hero.module.css";
import Playstore from "../../../assets/landingPage/playstore.svg";
import playMobile from "../../../assets/landingPage/playMobile.svg";
import Apple from "../../../assets/landingPage/Apple.svg";
import AppleMobile from "../../../assets/landingPage/appleMobile.svg";
import HeroImage from "../../../assets/landingPage/hero-1.png";
import Flip from "../../../assets/landingPage/flip.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.heroSplit}>
          <motion.div
            className={classes.heroContent}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h1>Giving you seamless and endless payment possibilities</h1>
            <p>
              You have the money and we help you extend the reach of how you can
              optimize your money, providing you with the best services and
              seamless financial lifestyle.
            </p>
            <div className={classes.storeBtn}>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
                  target="_blank"
                >
                  <img
                    src={Playstore}
                    alt="store"
                    className={classes.storeDesk}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
                  target="_blank"
                >
                  <img
                    src={playMobile}
                    alt="store"
                    className={classes.storeMobile}
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://apps.apple.com/app/phast-app/id6451208309"
                  target="_blank"
                >
                  <img src={Apple} alt="store" className={classes.storeDesk} />
                </a>
                <a
                  href="https://apps.apple.com/app/phast-app/id6451208309"
                  target="_blank"
                >
                  <img
                    src={AppleMobile}
                    alt="store"
                    className={classes.storeMobile}
                  />
                </a>
              </div>
            </div>
          </motion.div>
          <div className={classes.heroPhone}>
            <img src={HeroImage} alt="iphone" />
            <img src={Flip} alt="iphone" className={classes.behind} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
