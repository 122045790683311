import classes from "./personal.module.css";

const Personal = () => {
  return (
    <div className={classes.main}>
      <section>
        <h5>Use of Your Personal Data</h5>
        <p>
          The Company may use Personal Data for the following purposes: <br />
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </p>
        <div className={classes.definition}>
          <p>
            <span>To provide and maintain our Service,</span> including to
            monitor the usage of our Service.
          </p>
          <p>
            <span>To manage Your Account:</span> to manage Your registration as
            a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </p>
          <p>
            <span>For the performance of a contract:</span> the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </p>
          <p>
            <span>To contact You:</span> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </p>
          <p>
            <span>To provide You with news,</span> special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </p>
          <p>
            <span>To manage Your requests:</span> To attend and manage Your
            requests to Us.
          </p>
          <p>
            <span>For business transfers:</span> We may use Your information to
            evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </p>
          <p>
            <span>For other purposes:</span> We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </p>
          <br/>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <p>
            <span>With Service Providers:</span> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </p>
          <p>
            <span>For business transfers: </span> We may share or transfer Your
            personal information in connection with, or during negotiations of,
            any merger, sale of Company assets, financing, or acquisition of all
            or a portion of Our business to another company.
          </p>
          <p>
            <span>With Affiliates:</span> We may share Your information with Our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </p>
          <p>
            <span> With business partners:</span> We may share Your information
            with Our business partners to offer You certain products, services
            or promotions.
          </p>

          <p>
            <span> With other users:</span> when You share personal information
            or otherwise interact in the public areas with other users, such
            information may be viewed by all users and may be publicly
            distributed outside.
          </p>
          <p>
            <span> With Your consent:</span> We may disclose Your personal
            information for any other purpose with Your consent.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Personal;
