import JoinCard from "../joinCard/joinCard";
import classes from "./joinPage.module.css";
import { positionData } from "../../../../constant/constant";
import { useState, useMemo } from "react";

const JoinPage = () => {
  const [department, setDepartment] = useState("");

  const mappedArray = useMemo(() => {
    if (department !== "") {
      const newArray = positionData.filter(
        (item) => item.department === department
      );
      return newArray;
    } else {
      return positionData;
    }
  }, [department]);
  return (
    <div className={classes.main}>
      <div className={classes.joinUs}>
        <div className={classes.position}>
          <p>Open Positions</p>
          <div className={classes.form}>
            <label htmlFor="choose">
              <select
                defaultValue="department"
                id="choose"
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="department" disabled>
                  Department
                </option>
                <option value="engineering">Engineering</option>
                <option value="legal">Legal</option>
                <option value="product">Product</option>
                <option value="human resource">Human resource</option>
              </select>
            </label>
          </div>
        </div>
        <div className={`animate__animated animate__zoomIn ${classes.card}`}>
          {department === "" ? (
            <h2>Choose a Department</h2>
          ) : (
            <div>
              <h3>{department}</h3>
              {mappedArray.map((item, i) => (
                <JoinCard {...item} key={i} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JoinPage;
