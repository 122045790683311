import classes from "./navBar.module.css";
import { HiChevronDown } from "react-icons/hi";
import { Link } from "react-router-dom";
import { companyData, featureData, helpData } from "../../../../constant/nav";
import OutsideClickHandler from "react-outside-click-handler";
import { useState } from "react";

const NavBar = ({ setCloseMenu }) => {
  return (
    <>
      <nav className={classes.main}>
        <div className={classes.btn}>
          <Dropdown1 setCloseMenu={setCloseMenu} />
        </div>
        <div className={classes.btn}>
          <Dropdown2 setCloseMenu={setCloseMenu} />
        </div>
        <div className={classes.btn}>
          <Dropdown3 setCloseMenu={setCloseMenu} />
        </div>
      </nav>
    </>
  );
};

export default NavBar;

const Dropdown1 = ({ setCloseMenu }) => {
  const [drop, setDrop] = useState(false);

  const handleDrop = () => {
    setDrop(true);
  };

  return (
    <div className={classes.navLink}>
      <button onClick={handleDrop}>
        Features{" "}
        <HiChevronDown className={drop ? classes.rotate : classes.rotateDown} />
      </button>
      {/* added span to cover button when dropdown is active */}
      {drop && <span className={classes.span}></span>}
      {drop && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setDrop(false);
          }}
        >
          <div className={classes.featureDrop}>
            {featureData.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                onClick={() => {
                  setDrop((prev) => !prev);
                  setCloseMenu((prev) => !prev);
                }}
              >
                <img src={item.icon} alt="icon" />
                <p>{item.title}</p>
              </Link>
            ))}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

const Dropdown2 = ({ setCloseMenu }) => {
  const [drop, setDrop] = useState(false);

  const handleDrop = () => {
    setDrop(true);
  };
  return (
    <div className={classes.navLink}>
      <button onClick={handleDrop}>
        Company{" "}
        <HiChevronDown className={drop ? classes.rotate : classes.rotateDown} />
      </button>
      {drop && <span className={classes.span}></span>}
      {drop && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setDrop(false);
          }}
        >
          <div className={classes.companyDrop}>
            {companyData.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                onClick={() => {
                  setDrop(false);
                  setCloseMenu((prev) => !prev);
                }}
              >
                <p>{item.title}</p>
              </Link>
            ))}
            <a
              href="https://medium.com/@info_74612"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};
const Dropdown3 = ({ setCloseMenu }) => {
  const [drop, setDrop] = useState(false);

  const handleDrop = () => {
    setDrop(true);
  };
  return (
    <div className={classes.navLink}>
      <button onClick={handleDrop}>
        Help{" "}
        <HiChevronDown className={drop ? classes.rotate : classes.rotateDown} />
      </button>
      {drop && <span className={classes.span}></span>}
      {drop && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setDrop(false);
          }}
        >
          <div className={classes.helpDrop}>
            {helpData.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                onClick={() => {
                  setDrop(false);
                  setCloseMenu((prev) => !prev);
                }}
              >
                <p>{item.title}</p>
              </Link>
            ))}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};
