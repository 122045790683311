import { useState } from "react";
import classes from "./header.module.css";
import Logo from "../../ui/logo/logo";
import NavBar from "./navBar/navBar";
import Button from "../button/button";
import Menu from "../../../assets/landingPage/hamburger.svg";
import { GrClose } from "react-icons/gr";

const Header = () => {
  const [closeMenu, setCloseMenu] = useState(false);

  return (
    <>
      <header className={classes.main}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <Logo setCloseMenu={setCloseMenu} />
          </div>
          <div
            className={`${classes.navbar} ${closeMenu ? classes.openNav : ""}`}
          >
            <div className={classes.nav}>
              <NavBar setCloseMenu={setCloseMenu} />
            </div>
            <div className={classes.headerBtn}>
              <Button
                title="Get Started"
                href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
              />
            </div>
          </div>
          {closeMenu ? (
            <button
              className={classes.close}
              onClick={() => setCloseMenu(false)}
            >
              <GrClose />
            </button>
          ) : (
            <button className={classes.menu} onClick={() => setCloseMenu(true)}>
              <img src={Menu} alt="menu" />
            </button>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
