import classes from "./review.module.css";
import ReviewCard from "./reviewCard/reviewCard";


const Review = () => {
  return (
    <div className={classes.main}>
      <div className={classes.reviewContent}>
        <h2>What they are saying about us </h2>
        <p>
        We fulfil our promise and they are here to testify
        </p>
      </div>
      <div>
        <ReviewCard />
      </div>
    </div>
  );
};

export default Review;
