import Button from "../../../ui/button/button";
import classes from "./aboutHero.module.css";
import HeroBg from "../../../../assets/company/about-bg.webp";
import Akin from "../../../../assets/company/akinyemi.png";
import Sarah from "../../../../assets/company/sarah.png";
import { motion } from "framer-motion";

const AboutHero = () => {
  return (
    <div className={classes.main}>
      <div className={classes.aboutSplit}>
        <motion.div
          className={classes.aboutContent}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 1 }}
          transition={{ duration: 1 }}
        >
          <h2>What do we aim to achieve with Phast?</h2>
          <p>
            We want to push the inherent power of payment technology to the moon
            such that utmost fluidity can be attained while sending and
            recieving payments.
          </p>
          <div>
            <Button title="Get Started" />
          </div>
        </motion.div>
        <motion.div
          className={classes.aboutImg}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 1 }}
          transition={{ duration: 1 }}
        >
          <img src={HeroBg} alt="" />
        </motion.div>
      </div>
      <div className={classes.mission}>
        <motion.div
          className={classes.missionContent}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>Our Mission</h2>
          <p>
            {" "}
            At phast, we’re on a mission to bank the unbanked population ,
            proivde premium and modern banking services at a cheaper rate to the
            general population, and futher connect Africa to the global payment
            network by facilitating high end to end seamlesss cross-border
            transactions{" "}
          </p>
        </motion.div>
        <div className={classes.board}>
          <motion.div
            className={classes.boardMember}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={Akin} alt="akinyemi" />
            <h5>Charles Ademola Akinyemi</h5>
            <p>Founder, CTO</p>
          </motion.div>
          <motion.div
            className={classes.boardMember}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={Sarah} alt="longe" />
            <h5>Sarah Longe Zion</h5>
            <p>Co-Founder, CEO</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
