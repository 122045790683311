import classes from "./joinUsLayout.module.css";
import Pecunia from "../../assets/landingPage/Logo.svg";
import { positionData } from "../../constant/constant";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../../components/ui/button/button";
import Pin from "../../assets/company/pin.svg";

const JoinUsLayout = () => {
  const [newPosition, setNewPosition] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const newId = positionData.find((item) => item.id === id);
      setNewPosition(newId);
    }
  }, []);
  return (
    <div className={classes.main}>
      <Link to="/">
        <img src={Pecunia} alt="icon" />
      </Link>
      <div className={classes.joinBtn}>
        <a
          href=" https://docs.google.com/forms/d/e/1FAIpQLScNIriYyU4xNtgq3-xpYe8q2SNKIw-RgRMBAumywoLKAuaHBw/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.apply}
        >
          Apply
        </a>
      </div>
      <div className={classes.joinContent}>
        <h4>{newPosition.position}</h4>
        <div className={classes.pin}>
          <img src={Pin} alt="pin" />
          <p>
            {newPosition.location}, <span>{newPosition.type}</span>
          </p>
        </div>
        <div>
          <div className={classes.description}>
            <h5>Job Description:</h5>
            <ol>
              {newPosition.description &&
                newPosition.description.map((item, i) => (
                  <li key={i}>{item.content}</li>
                ))}
            </ol>
          </div>
          <div className={classes.description}>
            <h5>Job Requirement:</h5>
            <ol>
              {newPosition.requirement &&
                newPosition.requirement.map((item, i) => (
                  <li key={i}>{item.content}</li>
                ))}
            </ol>
          </div>
        </div>
      </div>
      <div className={classes.joinBtn}>
        <a
          href=" https://docs.google.com/forms/d/e/1FAIpQLScNIriYyU4xNtgq3-xpYe8q2SNKIw-RgRMBAumywoLKAuaHBw/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.apply}
        >
          Apply
        </a>
      </div>
    </div>
  );
};

export default JoinUsLayout;
