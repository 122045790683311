import AboutHero from "./aboutHero/aboutHero";
import Review from "../../../components/LandingComp/review/review";
import { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <AboutHero />
      <Review />
    </>
  );
};

export default AboutUs;
