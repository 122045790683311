import classes from "./hero.module.css";
import heroCard from "../../../../assets/featurePage/cashback/cash-hero.webp";
import Button from "../../../ui/button/button.js";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.heroSplit}>
          <motion.div
            className={classes.heroContent}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h1>Cashback & Referral Bonus</h1>
            <p>
              Earn a cashback when you use our card for your online transactions
              and also get to earn when you refer friends and family to the
              Phast family.
            </p>
            <div className={classes.storeBtn}>
              <Button
                title="Earn Bonus"
                href="https://play.google.com/store/apps/details?id=com.phastpay.phastmobile"
              />
            </div>
          </motion.div>

          <div className={classes.heroPhone}>
            <img src={heroCard} alt="iphone" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
