import classes from "./hero.module.css";
import Heroimg from "../../../../assets/help/help-hero.webp";
import Call from "../../../../assets/help/call.svg";
import Mail from "../../../../assets/help/mail.svg";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.heroSplit}>
          <motion.div
            className={classes.heroContent}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 1 }}
            transition={{ duration: 1 }}
          >
            <h1>We are only one click away</h1>
            <p>
              Is there anything you would love to talk to us about? , Do you
              have any challenges you want us to help you look into?, We are
              only click away
            </p>
            <div className={classes.storeBtn}>
              <div>
                <a href="tel:01-920-000" className={classes.btn}>
                  <img src={Call} alt="" />
                  <h4>Call 01-920-000</h4>
                </a>
              </div>
              <div>
                <a
                  href="mailto:Info@phastpay.com"
                  target="_blank"
                  className={classes.btn}
                >
                  <img src={Mail} alt="" />
                  <h4>Email Us</h4>
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div
            className={classes.heroPhone}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 1 }}
            transition={{ duration: 1 }}
          >
            <img src={Heroimg} alt="iphone" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Hero;
