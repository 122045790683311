import classes from "./reviewCard.module.css";
import { reviewData } from "../../../../constant/constant";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

const ReviewCard = () => {
  return (
    <div className={classes.main}>
      <Splide
        hasTrack={false}
        options={{
          type: "loop",
          perPage: 3,
          perMove: 1,
          autoplay: true,
          focus: "center",
          drag: "free",
          interval: 3000,
          arrows: false,
          pagination: false,
          pauseOnHover: false,
          padding: "6rem",
          mediaQuery: "max",
          breakpoints: {
            1340: {
              perPage: 2,
              padding: "10%",
            },
            1024: {
              padding: "8%",
            },
            768: {
              perPage: 1,
              padding: "15%",
            },
            425: {
              perPage: 1,
              padding: "1rem",
            },
          },
        }}
      >
        <SplideTrack>
          {reviewData.map((item, index) => (
            <SplideSlide key={index}>
              <div className={classes.reviewCard}>
                <div className={classes.user}>
                  <img src={item.icon} alt="customer" />
                  <h4>{item.name}</h4>
                </div>
                <p>{item.review}</p>
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  );
};

export default ReviewCard;
