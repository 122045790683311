import classes from "./joinCard.module.css";
import Pin from "../../../../assets/company/pin.svg";
import Arrow from "../../../../assets/company/right-arrow.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const JoinCard = ({ position, location, type, id }) => {
  return (
    <>
      <motion.div
        key={id}
        className={classes.main}
        initial={{ opacity: 0, x: -100 }}
        animate={{opacity: 1, x: 1}}
        transition={{ duration: 0.5 }}
      >
        <Link to={`/company/join-us/${id}`} className={classes.linkPosition}>
          <div>
            <h3>{position}</h3>
            <div className={classes.pin}>
              <img src={Pin} alt="pin" />
              <p>
                {location}, <span>{type}</span>
              </p>
            </div>
          </div>
          <img src={Arrow} alt="right arrow" />
        </Link>
      </motion.div>
    </>
  );
};

export default JoinCard;
